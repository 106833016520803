/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faChild,
  faHospitalUser,
} from "@fortawesome/pro-regular-svg-icons"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import { Hero } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ColorBar,
  HeroGradient,
  LabelIcon,
  PageLabel,
  PatientStoryGrid,
  QuickLink,
  QuickLinks,
  SectionHeading,
} from "../../components/elements"

const MedicalProfessionals = ({ data, pageContext }) => {
  const page = data.datoCmsMedicalProfessional
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Main>
        <Hero>
          <BackgroundImage Tag="div" fluid={page.heroImage.fluid}>
            <HeroGradient>
              <Container sx={{ px: "1em", py: [6, 6, 7] }}>
                <Styled.h1>{page.title}</Styled.h1>
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=" / "
                  crumbLabel={page.title}
                />
              </Container>
            </HeroGradient>
            <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
          </BackgroundImage>
        </Hero>

        {/* IntroContent */}
        <Section id="intro">
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap" }}>
              <Box sx={{ width: ["full", "full", "4/5"] }}>
                <Styled.h6 as="p">{page.introContent}</Styled.h6>
              </Box>
              <Text
                variant="styles.twoCols"
                sx={{ width: ["full"] }}
                dangerouslySetInnerHTML={{
                  __html: page.contentNode.childMarkdownRemark.html,
                }}
              />
            </Flex>
          </Container>
        </Section>

        {/* Refer/Transfer Patients */}
        <Section id="refer-patient" sx={{ backgroundColor: ["warmgray"] }}>
          <Container sx={{ px: "1em" }}>
            <Box sx={{ textAlign: "center", mx: "auto", width: "full" }}>
              <SectionHeading>Referring/Transferring Patients</SectionHeading>
            </Box>
            <Flex
              sx={{
                width: "full",
                flexWrap: "wrap",
                mx: [0, -2],
                justifyContent: "center",
              }}
            >
              <Box
                sx={{ width: ["full", "1/2", "1/3"], px: [0, 2] }}
                variant="styles.quickLinks"
              >
                <a href="https://wvumedicine.org/providerhub/how-to-refer-a-patient/">
                  <PageLabel sx={{ bg: "white" }}>
                    <LabelIcon bgColor="#9ABEAA" title="Refer a Patient">
                      <FontAwesomeIcon icon={faChild} className="fa-lg" />
                    </LabelIcon>
                    <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                  </PageLabel>
                </a>
              </Box>

              <Box
                sx={{ width: ["full", "1/2", "1/3"], px: [0, 2] }}
                variant="styles.quickLinks"
              >
                <a href="https://wvumedicine.org/providerhub/transfer-a-patient/">
                  <PageLabel sx={{ bg: "white" }}>
                    <LabelIcon bgColor="#CD4C08" title="Transfer a Patient">
                      <FontAwesomeIcon
                        icon={faHospitalUser}
                        className="fa-lg"
                      />
                    </LabelIcon>
                    <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                  </PageLabel>
                </a>
              </Box>
            </Flex>
          </Container>
        </Section>

        {/* Specialists Directory */}
        <Section id="specialists-directory">
          <Container sx={{ px: "1em" }}>
            <Flex
              sx={{ flexWrap: "wrap", justifyContent: "center", width: "full" }}
            >
              <Box sx={{ mx: "auto", textAlign: "center", width: "full" }}>
                <SectionHeading>Specialists Directory</SectionHeading>
              </Box>

              {page.specialistsDirectory.map(
                ({ title, document, externalLink }) => (
                  <Box
                    as="a"
                    sx={{
                      color: "initial",
                      px: [0, 2],
                      textDecoration: "none",
                      width: ["full", "1/2", "1/3"],
                    }}
                    variant="styles.quickLinks"
                    href={document ? document.url : externalLink}
                  >
                    <PageLabel
                      sx={{
                        height: "full",
                        bg: "white",
                        py: 3,
                        borderLeftWidth: "4px",
                        borderLeftColor: "children.2",
                      }}
                    >
                      <span>{title}</span>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="fa-lg"
                      />
                    </PageLabel>
                  </Box>
                )
              )}
            </Flex>
          </Container>
        </Section>

        {/* Physicians Liaisons */}
        {page.liasons.length > 0 && (
        <Section id="liaisons" sx={{ backgroundColor: ["warmgray"] }}>
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
              <Box
                sx={{
                  width: ["full", "full", "4/5"],
                  mb: 4,
                  textAlign: "center",
                }}
              >
                <SectionHeading>Physician Liaisons</SectionHeading>
                <Styled.p variant="intro">{page.liasonsIntro}</Styled.p>
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  width: ["full", "1/2", "1/3"],
                }}
              >
                {page.liasons.map(({ ...liasons }) => (
                  <PageLabel key={liasons.id}>
                    <Flex
                      sx={{
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box sx={{ width: ["1/4", "full"] }}>
                        <Img
                          fluid={liasons.photo.fluid}
                          alt={`${liasons.name} Portrait`}
                          sx={{
                            mb: 0,
                            height: "60px",
                            width: "60px",
                            mx: "auto",
                            borderRadius: "full",
                            textAlign: "center",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          pl: [3, 0],
                          textAlign: ["left", "center"],
                          width: ["3/4"],
                        }}
                      >
                        <Styled.h3 sx={{ mb: 0 }}>{liasons.name}</Styled.h3>
                        <Text as="p" sx={{ fontSize: 0 }}>
                          <a href={`mailto:${liasons.email}`}>
                            {liasons.email}
                          </a>
                          <br />
                          Phone:{" "}
                          <a href={`tel:${liasons.phoneNumber}`}>
                            {liasons.phoneNumber}
                          </a>
                        </Text>
                      </Box>
                    </Flex>
                  </PageLabel>
                ))}
              </Box>
            </Flex>
          </Container>
        </Section>    
        )}

        {/* Stories */}
        <Section data-datocms-noindex>
          <Container>
            <Box sx={{ width: "full", textAlign: "center", mb: 4 }}>
              <SectionHeading>Patient Stories</SectionHeading>
              <PatientStoryGrid />
              <br />
            </Box>
          </Container>
        </Section>

        {/* QuickLinks */}
        <QuickLinks>
          {page.quicklinks.map(
            ({ id, linkTitle, internalLinkUrl, extrenalLinkUrl }) => (
              <QuickLink
                key={id}
                to={extrenalLinkUrl || internalLinkUrl}
                title={linkTitle}
              />
            )
          )}
        </QuickLinks>
      </Main>
    </Layout>
  )
}

export default MedicalProfessionals

export const query = graphql`
  {
    datoCmsMedicalProfessional {
      id
      introContent
      slug
      title
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      liasonsIntro
      liasonsIntroNode {
        childMarkdownRemark {
          html
        }
      }
      liasons {
        email
        id
        name
        phoneNumber
        photo {
          fluid(maxWidth: 240, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      quicklinks {
        id
        linkTitle
        internalLinkUrl
        extrenalLinkUrl
      }
      specialistsDirectory {
        title
        externalLink
        document {
          alt
          url
          title
        }
      }
      seoMetaTags {
        tags
      }
    }
  }
`
